const $ = require('jquery');
window.jQuery = $;
window.$ = $;
require('waypoints/lib/jquery.waypoints');


let waypoints = [];

$('.youtube-player').each(function (index) {
    let $this = $(this);

    waypoints[index] = $this.waypoint(function (direction) {
        if( direction === 'down' && $this.data('autoplay') && !$this.hasClass('is-playing') ) {
            let $iframe = $this.find('iframe');
            let src = $iframe.data('src');

            $iframe.attr('src', src + '&autoplay=1&mute=1');

            $this.addClass('is-playing');
        }
    }, {
        offset: '110%'
    });
});
