import $ from 'jquery';

$(() => {
  const $projectList = $('.projects');
  const $projectFilter = $('.project-filter');

  const updateFilter = (value) => {
    const $projects = $projectList.find('a.column').removeClass('is-filtered-out');

    if (value) {
      $projects.filter(function () {
        const category = $(this).data('category');
        return category.indexOf(value) === -1;
      }).addClass('is-filtered-out');
    }
  }

  $projectFilter.on('change', 'select', function () {
    updateFilter($(this).val());
  });

  $projectFilter.on('click', 'li a', function () {
    updateFilter($(this).data('category'));
    return false;
  });
});