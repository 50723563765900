import $ from 'jquery';
import { getScrollOffset } from './scrollbar-measure';

$(() => {
  let isNeos = false;

  const closeSegments = (push = true) => {
    const $openArticles = $('.accordion article.is-open');
    if ($openArticles.length > 0) {
      const $overlay = $openArticles.parents('.is-overlay');
      if (push) {
        if ($overlay.length > 0) {
          history.pushState('', document.title, `${window.location.pathname}#!${$overlay.attr('id')}`);
        } else {
          history.pushState('', document.title, window.location.pathname);
        }
      }
      $openArticles.removeClass('is-open').find('> div').css('height', '');
    }
  };

  const $accordions = $('.accordion').on('click', 'article > a', function () {

    const target = $(this).attr('href').substring(2);
    const $target = $(`#${target}`);

    if ($target.hasClass('is-open')) {
      closeSegments();
    } else {
      history.pushState('', document.title, `#!${target}`);
      $(window).trigger('hashchange.accordion');
    }

    return false;
  });

  let initial = true;
  let windowWidth = 0;
  const $window = $(window)
    .on('resize.accordion', () => {
      const newWindowWidth = $window.width();
      if (newWindowWidth !== windowWidth) {
        windowWidth = newWindowWidth;
        closeSegments();
      }
    })
    .on('hashchange.accordion', (e) => {
      const hash = window.location.hash;

      if (hash.startsWith('#!')) {
        const target = hash.substring(2);

        const $target = $(`#${target}`);
        if ($target.length === 1) {
          const $accordion = $target.parents('.accordion').eq(0);

          if ($accordion.length === 1) {
            if ($target.hasClass('will-open') || $target.hasClass('is-open')) {
              return;
            }

            closeSegments(false);

            const $content = $target.find('> div');

            const open = () => {
              $target.addClass('will-open').height();
              const height = $content.height();
              $target.removeClass('will-open').height();
              if (initial) {
                $content.css('transition', 'none').height();
              }
              $target.addClass('is-open');
              $content.css('height', height);

              if (initial) {
                setTimeout(() => $content.css('transition', ''), 100);
              }
              e.preventDefault();

              let $scrollParent = $target.parents('.is-overlay');
              let isInOverlay = true;
              if ($scrollParent.length === 0) {
                $scrollParent = $('html, body');
                isInOverlay = false;
              }

              if (!isNeos) {
                if (initial) {
                  setTimeout(() => {
                    $scrollParent.prop('scrollTop', $target.offset().top + (isInOverlay ? 0 : getScrollOffset()));
                  }, 100);

                } else {
                  setTimeout(() => {
                    let scrollTop = $target.offset().top + (isInOverlay ? 0 : getScrollOffset());
                    if ($scrollParent.hasClass('is-overlay')) {
                      scrollTop += $scrollParent.prop('scrollTop');
                    }
                    $scrollParent.animate({scrollTop});
                  }, 500);
                }
              }
              initial = false;
              if (isNeos) {
                history.replaceState('', document.title, window.location.pathname);
              }
            };

            open();

            return;
          }
        }
      }
      initial = false;
      $accordions.find('article').removeClass('is-open');
    }).trigger('hashchange');

  document.addEventListener('Neos.ContentModuleLoaded', (event) => {
    isNeos = true;
  }, false);
});
