import $ from 'jquery';
import _ from 'underscore';
import skrollr from 'skrollr';
import { getScrollOffset } from './scrollbar-measure';
import { isTablet, isDesktop } from './responsive-helper';

let skrollrInstance = null;

export const enableSkrollr = () => {
  if (isDesktop() && !skrollrInstance) {
    skrollrInstance = skrollr.init({
      smoothScrolling: false,
      forceHeight: false
    });
  }
};

export const disableSkrollr = () => {
  if (skrollrInstance) {

    // Save parallexs

    const scrollTop = $('body').scrollTop();
    const $backgrounds = $('.hero.is-parallax .hero-background.skrollable').each(function () {
      const backgroundPosition = $(this).css('background-position');
      if (!backgroundPosition) {
        return;
      }

      const posY = scrollTop - $(this).offset().top + parseFloat(backgroundPosition.split(' ')[1]);
      $(this).data('background-position-y', posY);
    });

    skrollrInstance.destroy();
    skrollrInstance = false;

    // set parallaxes
    $backgrounds.each(function () {
      const posY = $(this).data('background-position-y');
      if (typeof posY !== 'undefined') {
        $(this).css('background-position', `50% ${posY}px`);
        $(this).removeData('background-position-y');
      }
    });

  }
};

$(() => {

  enableSkrollr();

  $(window).on('resize', _.debounce(() => {
    if (isDesktop()) {
      enableSkrollr();
    } else {
      disableSkrollr();
    }
  }, 500));

  let lastScrollTop = 0;
  let scrollDirection = 'down';
  let isNeos = false;
  $(window)
    .on('scroll', () => {
      const scrollTop = $(document).scrollTop();
      scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';
      lastScrollTop = scrollTop;
    })
    .on('scroll resize', _.debounce(() => {
      if (isNeos || !isDesktop()) {
        return;
      }

      const viewport = { top: $(document).scrollTop() };
      viewport.height = window.innerHeight;
      viewport.bottom = viewport.top + viewport.height;

      const selectors = ['.hero.is-fullheight'];
      if (isTablet()) {
        selectors.push('.hero.is-fullheight-tablet');
      }

      const $heros = $(selectors.join(',')).filter((index, hero) => {
        const $hero = $(hero);
        const top = $hero.offset().top;
        const bottom = top + $hero.outerHeight();
        if (top >= viewport.top && top <= viewport.bottom) {
          const visible = 1 - ((top - viewport.top) / viewport.height);
          if (visible > 0) {
            return true;
          }
        } else if (bottom >= viewport.top && bottom <= viewport.bottom) {
          const visible = (bottom - viewport.top) / viewport.height;
          if (visible > 0) {
            return true;
          }
        }

        return false;
      });

      if ($heros.length > 1) {
        const $targetHero = scrollDirection === 'down' ? $heros.last() : $heros.first();
        const scrollTop = $targetHero.offset().top;

        if (skrollrInstance) {
          skrollrInstance.animateTo(scrollTop, {
            duration: 300
          });
        } else {
          $('html, body').animate({ scrollTop });
        }
      }
    }, 500));

  $('body').on('click', '.read-more', function () {
    const $this = $(this);
    const $hero = $this.parents('.hero');
    const scrollTop = $hero.offset().top + $hero.outerHeight() + getScrollOffset();

    if (skrollrInstance) {
      skrollrInstance.animateTo(scrollTop, {
        duration: 300
      });
    } else {
      $('html, body').animate({ scrollTop });
    }
    return false;
  })
  .on('mouseover', '.hero-cta', function () {
    $(this).parent().addClass('has-mouseover');
  })
  .on('mouseout', '.hero-cta', function () {
    $(this).parent().removeClass('has-mouseover');
  });

  document.addEventListener('Neos.ContentModuleLoaded', () => {
    isNeos = true;
  }, false);
});
