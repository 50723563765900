import $ from 'jquery';
import { getScrollOffset } from './scrollbar-measure';
import { isMobile, isDesktop } from './responsive-helper';
import { debounce } from 'underscore';

const TRANSITION_LENGTH = 800;
const FADE_DURATION = 500;

$(() => {
  const $teamList = $('.team-list');

  if ($teamList.length === 0) {
    return;
  }

  const iteration = $teamList.data('iteration');
  const startingCount = $teamList.data('initial');

  const $search = $teamList.parent().find('input[type="search"]');
  const $loadMore = $teamList.parent().find('.load-more');

  const updateList = ({ term = null, category = null, expand = true, keep = null } = {}) => {

    if ($teamList.find('.detail.is-added').length > 0) {
      closeMember();
      history.pushState('', document.title, window.location.pathname);
      setTimeout(() => updateList({term, category}), TRANSITION_LENGTH);
      return;
    }

    const $allMembers = $teamList.find('a.column');

    if (term !== null) {
      term = term.toLowerCase();
      $allMembers.removeClass('is-filtered-out');
      if (term !== '') {
        $allMembers.filter(function () {
          const name = $(this).data('name');
          return name.indexOf(term) === -1;
        }).addClass('is-filtered-out');
      }
    } else if (category) {
      $allMembers.removeClass('is-filtered-out');
      if (category !== 'all') {
        $allMembers.filter(function () {
          const categories = $(this).data('category');
          return categories.indexOf(category) === -1;
        }).addClass('is-filtered-out');
      }
    }

    if (expand === false || term !== null || category !== null) {
      $allMembers.removeClass('is-invisible').not('.is-filtered-out').slice(startingCount).addClass('is-invisible');
      if (keep) {
        if (keep.hasClass('is-invisible')) {
          $allMembers.not('.is-invisible').not('.is-filtered-out').last().addClass('is-invisible');
          keep.removeClass('is-invisible');
        }
      }
    } else if (expand) {
      $allMembers.filter('.is-invisible').slice(0, iteration).removeClass('is-invisible');
    }

    if ($allMembers.filter('.is-invisible:not(.is-filtered-out)').length === 0) {
      $loadMore.hide();
    } else {
      $loadMore.show();
    }

    const $uninitializedMembers = $allMembers.not('.is-invisible').not('.is-filtered-out').not('.preview-initialized');
    $uninitializedMembers.each(function () {
      const $member = $(this);
      const $placeholder = $member.find('span.img-placeholder');

      $('<img />')
        .attr('src', $placeholder.data('src'))
        .attr('alt', $placeholder.data('alt'))
        .insertBefore($placeholder);
      $placeholder.remove();
      $member.addClass('preview-initialized');
    });
  };

  $loadMore.on('click', (e) => {
    e && e.preventDefault();
    updateList();
  });

  const debouncedUpdateList = debounce(updateList);
  $search.on('keyup', function(e) {
    if (e.which === 13) {
      updateList({term: $search.val()});
    } else {
      debouncedUpdateList({ term: $search.val()});
    }
  });

  const $filter = $teamList.parent().find('.filter');
  $filter.on('click', 'li a', function () {
    updateList({category: $(this).data('category')});
    return false;
  });

  $filter.on('change', 'select', function () {
    updateList({category: $(this).val() });
  });


  let openMemberRow = -1;
  const closeMember = (clear = true) => {
    const $member = $teamList
      .find('.detail.is-added')
      .removeClass(`is-visible${clear ? ' is-delayed' : ''}`);

    if (isDesktop()) {
      $member.css('height', '');
    }

    if ($member.length > 0) {
      openMemberRow = -1;
      setTimeout(() => {
        $member.remove();
      }, TRANSITION_LENGTH);
    }

  };


  const openMember = ($anchor, direct = false) => {
    const id = $anchor.attr('href').replace(/^#!/, '');
    if ($teamList.find(`#${id}`).length > 0) {
      return null;
    }

    if ($anchor.hasClass('is-invisible')) {
      $anchor.removeClass('is-invisible');
      updateList({expand: false, keep: $anchor });
    }

      const $allVideos = $teamList
          .find('.detail video');

      $allVideos.each(function(i, video){
          $(video).closest('.detail').prev('a').removeClass('detail-initialized');
          video.remove();
      });

    const $detail = $anchor.nextAll('.detail:not(.is-added)').eq(0);

    if (!$anchor.hasClass('detail-initialized')) {
      $anchor.addClass('detail-initialized');

      const $image = $detail.find('.picture-placeholder');

      if ($image.length) {
        $(`<picture>
          <source media="(min-width: 769px) and (max-width: 1024px)" srcset="${$image.data('tablet')}, ${$image.data('tablet2x')} 2x">
          <source media="(min-width: 1025px)" srcset="${$image.data('desktop')}">
          <img src="${$image.data('phone')}" alt="${$image.data('alt')}" srcset="${$image.data('phone2x')} 2x">
        </picture>`).insertBefore($image);

        $image.remove();
      }

      const $video = $detail.find('.video-placeholder');

      if ($video.length) {
        $(`<video autoplay loop muted playsinline alt="${$video.data('alt')}">
            <source src="${$video.data('video')}" type="video/mp4">
        </video>`).insertBefore($video);

      }
    }

    const $parent = $anchor.parent();
    const $elements = $parent.find('> a.column:not(.is-invisible):not(.is-filtered-out)');
    const width = $anchor.width();
    const parentWidth = $parent.width();
    const countPerRow = Math.floor(1 / (width / parentWidth));


    let index = 0;
    $elements.each(function (i) {
      if (this === $anchor.get(0)) {
        index = i;
        return false;
      }
    });


    const currentRow = Math.floor(index / countPerRow) + 1;

    const hasMember = currentRow === openMemberRow;

    closeMember(!hasMember);
    openMemberRow = currentRow;
    let $lastInRow = $elements.eq((currentRow * countPerRow) - 1);
    if ($lastInRow.length === 0) {
      $lastInRow = $elements.last();
    }

    const position = Math.floor(index % countPerRow) + 1;

    const $clone = $detail.clone()
      .attr('id', id)
      .insertAfter($lastInRow)
      .addClass(`is-added position-${position}`);

    $clone.css('transition', 'none').addClass('is-visible');

    if (isMobile()) {
      $clone.find('.grid').width($clone.outerWidth() - 24);
    }

    const height = $clone.outerHeight();

    $clone.removeClass('is-visible').width();

    if (!direct) {
      $clone.css('transition', '');
    }

    $clone
      .addClass(`is-visible${hasMember || direct ? ' is-delayed' : ''}`)
      .css('height', height)
      .width();

    if (direct) {
      $clone.css('transition', '');
    } else {
      setTimeout(() => {
        $clone.addClass('is-delayed');
      }, TRANSITION_LENGTH);
    }

    const video = $clone.find('video').get(0);
    if (video) {
      video.addEventListener('canplay', () => {
        video.play();
      });
    }

    return $clone;
  };

  $teamList.on('click', 'a.column', function (e) {
    if ($(this).attr('href') === window.location.hash) {
      history.pushState('', document.title, window.location.pathname);
      closeMember();
      return false;
    }
    return true;
  });

  $teamList.on('click', '.close-button', () => {
    history.pushState('', document.title, window.location.pathname);
      const $allVideos = $teamList
          .find('.detail video');

      $allVideos.each(function(i, video){
          $(video).closest('.detail').prev('a').removeClass('detail-initialized');
          video.remove();
      });
    closeMember();
  });

  const $window = $(window);

  let lastWidth = $window.width();
  $window.on('resize', () => {
    const currentWidth = $window.width();

    if (currentWidth !== lastWidth) {
      lastWidth = currentWidth;
      history.pushState('', document.title, window.location.pathname);
      $teamList.find('.detail.is-added').remove();
    }
  });

  let initial = true;
  $(window).on('hashchange', (e) => {
    const hash = window.location.hash;

    if (hash.startsWith('#!')) {
      const $anchor = $teamList.find(`a[href="${hash}"]`);
      if ($anchor.length === 1) {
        const $info = openMember($anchor, initial);

        if ($info) {
          // Scroll top needs to be calculated inside timeout
          if (initial) {
            setTimeout(() => $('html, body').prop('scrollTop', $info.offset().top + 1 + getScrollOffset() - (isMobile() ? 190 : 215)), 1);
          } else {
            setTimeout(() => $('html, body').animate({ scrollTop: $info.offset().top + 1 + getScrollOffset() - (isMobile() ? 190 : 215) }), TRANSITION_LENGTH + 100);
          }
        }
      }
    } else {
      closeMember();
    }

    initial = false;
  }).trigger('hashchange');

  setTimeout(() => $teamList.addClass('is-animatable'), 1000);
});
