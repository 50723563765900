const $ = require('jquery');
window.jQuery = $;
window.$ = $;
require('lightslider');
require('lightgallery');
require('lg-pager/src/lg-pager');

const initSliders = () => {
  const inNeos = $('body').hasClass('neos-backend');
  $('.image-slider').lightSlider({
    item: 1,
    prevHtml: '<span></span><span></span>',
    nextHtml: '<span></span><span></span>',
    enableDrag: !inNeos,

    onSliderLoad(el) {
      if (!inNeos) {
        el.lightGallery({
          pager: true,
          animateThumb: false,
          counter: false,
          showThumbByDefault: false,
          download: false
        });
      }
    }
  });
};

document.addEventListener('Neos.PageLoaded', initSliders, false);
$(initSliders);

document.addEventListener('Neos.NodeCreated', (event) => {
  const element = event.detail.element;
  if (element.classList.contains('slider-image')) {
    Typo3Neos.Content.reloadPage();
  }
});

document.addEventListener('Neos.NodeRemoved', (event) => {
  const element = event.detail.element;
  if (element.classList.contains('slider-image')) {
    Typo3Neos.Content.reloadPage();
  }
});