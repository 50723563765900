import $ from 'jquery';
import Shuffle from 'shufflejs';
import { debounce } from 'underscore';
import { isMobile, isDesktop } from './responsive-helper';

$(() => {
  const $blockList = $('.blog-list');
  if ($blockList.length === 0) {
    return;
  }

  let instance;

  $blockList.on('click', 'article img', function () {
    window.location.href = $(this).parents('article').find('a').prop('href');
  });

  $blockList.find('img').on('load', () => {
    instance && instance.layout();
  });

  $blockList.parent().on('click', '.load-more', function (e) {
    e.preventDefault();
    const $this = $(this);
    if ($this.hasClass('is-loading')) {
      return;
    }

    $this.addClass('is-loading');
    const iterationCount = $this.data('iteration-count');
    const totalCount = $this.data('total-count');
    const currentCount = $blockList.find('article').length;

    $.ajax({
      data: {
        ajax: 'true',
        start: currentCount,
        type: 'blog',
        'length': iterationCount
      }
    }).then((result) => {
      const lis = $(result).filter('li').toArray();
      lis.forEach(li => $blockList.append(li));

      if (instance) {
        instance.add(lis);
      }

      $blockList.find('img').on('load', () => {
        instance && instance.layout();
      });

      if (currentCount + iterationCount >= totalCount) {
        $this.remove();
      } else {
        $this.removeClass('is-loading');
      }
    });
  });

  const init = () => {
    if (isMobile()) {
      if (instance) {
        instance.destroy();
        instance = null;
      }
    } else {
      if (!instance) {
        instance = new Shuffle($blockList.get(0), {
          itemSelector: 'li',
          speed: 500
        });
      }
    }
  };
  init();
  $(window).on('resize.shuffle', debounce(init));
});

$(() => {
  const $article = $('.blog-article');

  if (!$article.length) {
    return;
  }

  const $author = $article.find('.blog-author');
  const $collection = $article.find('> .neos-contentcollection');
  const $firstChild = $collection.find('> *:first-child');
  const $itemsUntilImage = $firstChild.nextUntil('.is-full-image-container').add($firstChild);

  let $spacer = null;
  const layout = () => {
    if ($spacer) {
      $spacer.remove();
    }

    if (isDesktop()) {
      const authorHeight = $author.outerHeight();
      let itemsHeight = 0;
      $itemsUntilImage.each(function () {
        itemsHeight += $(this).outerHeight(true)
      });

      if (authorHeight > itemsHeight) {
        console.log({itemsHeight, authorHeight});
        $spacer = $('<div />').height(authorHeight - itemsHeight).insertAfter($itemsUntilImage.last());
      }
    }
  };

  $(window).on('resize.blog', debounce(layout, 300));
  layout();
});


$(() => {
  const $buttonList = $('.blog-share ul');

  // event delegation
  $buttonList.on('click', '[data-rel="popup"]', function(e) {
      e.preventDefault();

      const url = $(this).attr('href');
      const windowName = '_blank';
      const windowSizeX = '600';
      const windowSizeY = '460';
      const windowSize = 'width=' + windowSizeX + ',height=' + windowSizeY;

      global.window.open(url, windowName, windowSize);

  });
});