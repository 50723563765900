import $ from 'jquery';
import { getScrollbarWidth } from './scrollbar-measure';

const TRANSITION_DURATION = 500;

$(() => {
  const $body = $('body');
  let $overlays = $();

  const initOverlays = () => {
    $overlays = $('.is-overlay').on('click', '[data-action="close"]', () => {
      closeOverlay();
      history.pushState('', document.title, window.location.pathname);
    });
  };
  initOverlays();
  const closeOverlay = () => {
    if (!$body.hasClass('is-overlay-open')) {
      return;
    }

    $overlays.removeClass('is-open');
    $neosOverlay = null;
    setTimeout(() => {
      const top = parseInt($body.css('top'), 10);
      $body
        .css('top', '')
        .css('right', '')
        .removeClass('is-overlay-open')
        .prop('scrollTop', -top);

      $overlays.removeClass('will-open');
    }, TRANSITION_DURATION);
  };

  let initial = true;
  let isNeos = false;
  let $neosOverlay = null;

  $(window).on('hashchange', (e) => {
    const hash = window.location.hash;
    if (hash.startsWith('#!')) {
      const target = hash.substring(2);

      let $overlay = $overlays.filter(`#${target}`);

      if ($overlay.length === 0) {
        const $target = $(`#${target}`);
        if ($target) {
          $overlay = $target.parents('.is-overlay');
        }
      }

      if ($overlay.length > 0) {
        const top = $body.prop('scrollTop');
        $body
          .css('top', -top)
          .css('right', getScrollbarWidth())
          .addClass('is-overlay-open');

        if (initial) {
          $overlay.addClass('no-transition');
        }

        $overlay.addClass('will-open').width();
        $overlay.addClass('is-open').width();

        if (initial) {
          $overlay.removeClass('no-transition');
        }

        e.preventDefault();
        initial = false;
        if (isNeos) {
          history.replaceState('', document.title, window.location.pathname);
          $neosOverlay = $overlay;
        }
        return;
      }
    }

    closeOverlay();
    $neosOverlay = null;
    initial = false;
  }).trigger('hashchange');

  document.addEventListener('Neos.PageLoaded', () => {
    initOverlays();
    if ($neosOverlay && $.contains(document, $neosOverlay[0])) {
      $body.addClass('is-overlay-open');
      $neosOverlay.addClass('no-transition');
      $neosOverlay.addClass('is-open').width();
      $neosOverlay.removeClass('no-transition');
      $neosOverlay = null;
    } else {
      $neosOverlay = null;
      $body.removeClass('is-overlay-open');
    }
  }, false);

  document.addEventListener('Neos.ContentModuleLoaded', () => {
    isNeos = true;
  }, false);
  //
});
