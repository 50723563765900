import $ from 'jquery';
import { getScrollbarWidth, getScrollOffset } from './scrollbar-measure';
import { enableSkrollr, disableSkrollr } from './hero';

const DURATION = (0.05 * 5 + 0.5) * 1000;

$(() => {
  const $body = $('body');
  const $menu = $('body > .menu');

  $menu.on('click', 'button[data-action=toggle]', () => {
    if ($body.hasClass('is-menu-open')) {

      $body
        .addClass('is-overlay-open')
        .removeClass('is-menu-open');

      setTimeout(() => {

        const top = parseInt($body.css('top'), 10);
        $body
          .css('top', '')
          .css('right', '')
          .removeClass('is-overlay-open')
          .prop('scrollTop', -top);

        enableSkrollr();
      }, DURATION);

    } else {


      disableSkrollr();

      const top = $body.prop('scrollTop');
      $body
        .css('top', -top)
        .css('right', getScrollbarWidth())
        .addClass('is-menu-open');
    }
  });

  $body.on('click', 'a[href^="#"]', function (e) {
    const href = $(this).attr('href');

    if (href.match(/^#[^!]/)) {
      $('html, body')
        .animate({
          scrollTop: $(href).offset().top + getScrollOffset()
        });
    }
  });
});
