import $ from 'jquery';
import { isMobile } from './responsive-helper';

let scrollbarWidth = 0;

$(() => {
  const measure = $('<div class="scrollbar-measure" />')
    .appendTo('body').get(0);

  scrollbarWidth = measure.offsetWidth - measure.clientWidth;
  $(measure).remove();
});

export const getScrollbarWidth = () => scrollbarWidth;
export const getScrollOffset = () => (isMobile() ? -59 : 0);
