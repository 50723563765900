import $ from 'jquery';
import {getScrollOffset} from './scrollbar-measure';

$(() => {

    const $form = $('#bewerben');

    $('body').on('click', '[data-job-apply]', function (e) {
        const index = $(this).data('job-apply');

        const $target = $('.jobs-select').eq(0);
        // const $form = $target.parents('form').eq(0);
        const $form = $('#bewerben');

        if ($target.length > 0) {
            $('html, body').animate({
                scrollTop: $form.offset().top + getScrollOffset()
            });

            $target.val(index).trigger('change');
        }

        return false;
    });

    function setIds() {
        const $target = $('.jobs-select').eq(0);
        const $submitButton = $('.palmer-form').find('.is-submit');
        const buttonID = 'submit-' + $target.val().toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

        $submitButton.attr('id', buttonID);
    }

    if ($form.length > 0) {
        setIds();
    }

    $('body').on('change', '.jobs-select', function (e) {
        setIds();
    });
});
