import $ from 'jquery';

const isTouchDevice = (() => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
})();

if (isTouchDevice) {
  $(() => {
    const $body = $('body');
    $body.on('click', 'a[data-hover]', function () {
      const $this = $(this);

      if (!$this.hasClass('is-hovered')) {
        $body.find('a[data-hover]').removeClass('is-hovered');
        $this.addClass('is-hovered');

        return false;
      }

      return true;
    });
  });
}