import $ from 'jquery';

$(() => {

  const $form = $('.palmer-form');

  $form.on('submit', () => {
    if ($form.find('input[type="file"][data-has-errors]').length) {
      return false;
    }
  });

  $form.on('click', '[data-file]', function () {
    const $group = $(this).parents('.form-group');
    const $file = $group.find('input[type="file"]').last();
    $file.click();
    return false;
  });

  $form.on('click', '[data-action="removeFile"]', function (e) {
    e.preventDefault();

    const $this = $(this);
    const $parent = $this.parent();
    const $group = $this.parents('.form-group');
    const $files = $this.parents('.files');
    const index = $files.find('> div').index($parent);

    $parent.remove();
    const $input = $group.find('input[type="file"]').eq(index);
    $input.prev('input[type="hidden"]').remove();
    $input.remove();
  });
  $form.on('change', 'input[type=file]', function () {

    const $this = $(this);
    const $container = $this.prevAll('.files');

    const maxFilesize = parseFloat($container.data('max-filesize')) * 1024 * 1024;
    const filesizeError = $container.data('filesize-error');

    $this.nextAll('.help-block').remove();
    $this.removeAttr('data-has-errors');

    if (this.files.length === 0)
      return;

    let totalSize = 0;
    $container.parent().find('input[type="file"]').each(function () {
      const $input = $(this);
      if ($input.data('size')) {
        totalSize += Number($input.data('size'));
      } else {
        totalSize += this.files[0].size;
      }
    });
    const file = this.files[0];

    $(`<div>
         <span>${file.name}</span>
         <a href="#" class="class="btn btn-link" data-action="removeFile">&times;</a>
       </div>`)
    .appendTo($container);

    $(this).clone().insertAfter(this).val(null);

    if (totalSize > maxFilesize && maxFilesize > 0) {
      $this.attr('data-has-errors', true);
      const $error = $(`<div class="help-block">${filesizeError}</div>`);
      $error.insertAfter($this);
    }
  });

});