const $ = require('jquery');
window.jQuery = $;
window.$ = $;
require('lightslider');

$(() => {
  const $closeup = $('.closeup');

  if ($closeup.length === 0) {
    return;
  }

  const slider = $closeup.find('.closeup-slider').lightSlider({
      auto: true,
      pause: 8000,
    item: 1,
    enableDrag: false,
    pager: false,
    autoWidth: false,
    controls: false,
    speed: 1500,
    slideMargin: 0,
    onBeforeSlide(element, index) {
      const $slide = $closeup.find('.closeup-content').eq(index);
      if ($slide.attr('data-background')) {
        $slide.css('background-image', `url(${$slide.attr('data-background')})`);
        $slide.removeAttr('data-background');
      }
    },
      onAfterSlide(element, index) {
          $closeup.find('.closeup-track a').removeClass('active');
          $closeup.find('.closeup-track a').eq(index).addClass('active');
      }
  });

  const activateAnchor = ($anchor) => {
    $closeup.find('.closeup-track a').removeClass('active');
    $anchor.addClass('active');

    slider.goToSlide($anchor.parent().index());
  };

  $closeup
    .on('click', '[data-action="closeup-open"]', () => {
      let suffix = '-detail';

      if (window.location.hash === '') {
        suffix = $closeup.find('.closeup-track a').eq(0).attr('href') + suffix;
      }

      window.location.href += suffix;
      return false;
    })
    .on('click', '.closeup-track a', function (e) {
      const $this = $(this);
      if ($this.parent().index() === 0) {
        history.pushState('', document.title, window.location.pathname);
        activateAnchor($this);
        return false;
      }
    });

  $(window).on('hashchange', () => {
    const hash = window.location.hash;
    if (hash.startsWith('#!')) {
      const anchorHash = hash.replace(/-info$/, '');
      const $anchor = $closeup.find(`a[href="${anchorHash}"]`);
      if ($anchor.length === 1) {
        activateAnchor($anchor);
      }
    }
  }).trigger('hashchange');

  if (window.location.hash === '') {
    activateAnchor($closeup.find('.closeup-track a').eq(0));
  }
});
