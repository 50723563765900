import $ from 'jquery';
import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

$(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    // init controller
    const controller = new ScrollMagic.Controller;

    const tween_r = TweenMax.to(".ceo-circle", 0.5, {rotation: 540, ease: Linear.easeNone});

    new ScrollMagic.Scene({
        duration: 300,
        offset: 0
    })
        .setTween(tween_r)
        //.addIndicators({name: "rotate"})
        .addTo(controller);

    const tween_show = TweenMax.to(".ceo-link", 0.5, {opacity: 1, ease: Linear.easeNone});

    new ScrollMagic.Scene({
        duration: 100,
        offset: 250
    })
        .setTween(tween_show)
        //.addIndicators({name: "show"}) // add indicators (requires plugin)
        .addTo(controller);

    if (window.screen.width >= 1024 && window.screen.height >= 768) {

        // build tween
        const tween = TweenMax.to("#ceo-linkedindex-button", 1, {scale: 0.6, ease: Linear.easeNone});

        // create a scene
        new ScrollMagic.Scene({
            duration: 300,	// the scene should last for a scroll distance of
            offset: 0	// start this scene after scrolling for
        })
            .setTween(tween)
            //.addIndicators({name: "resize"}) // add indicators (requires plugin)
            .addTo(controller);

        const tween_h = TweenMax.to(".ceo-text", 0.5, {opacity: 0, ease: Linear.easeNone});

        new ScrollMagic.Scene({
            duration: 100,
            offset: 250
        })
            .setTween(tween_h)
            //.addIndicators({name: "hide"}) // add indicators (requires plugin)
            .addTo(controller);

    }
});
