import $ from 'jquery';

$(() => {
  $('select[data-resizable]').on('change', function () {

    const $this = $(this);
    const $selected = $this.find('option:selected');

    const $clone = $('<select/>')
      .insertAfter(this)
      .append($selected.clone());

    const width = $clone.width();
    $clone.remove();
    $this.width(width);
  }).trigger('change');
});