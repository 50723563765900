import $ from 'jquery';
import 'select2';

const initForms = () => {
  const $form = $('.palmer-form');
  $form.find('select:not([multiple])').select2({
    minimumResultsForSearch: Infinity
  }).on('select2:open', function () {
    const select2 = $(this).data('select2');
    $(window).off(`scroll.select2.${select2.id}`);
  });

  $form.find('select[multiple]').select2({
    minimumResultsForSearch: Infinity,
    theme: 'multiselect',
    closeOnSelect: false
  }).on('change', function () {
    const $this = $(this);

    const count = $this.val().length;
    const label = $this.data('selection');

    if (count > 0) {
      $this.next().find('input').attr('placeholder', label.replace(/\{count}/g, count));
    }
  }).each(function () {
    const $this = $(this);
    const $input = $this.next().find('input').attr('readonly', true);
    const count = $this.val().length;

    if (count > 0) {
      const label = $this.data('selection');
      $input.attr('placeholder', label.replace(/\{count}/g, count));
    }
  }).on('select2:open', function () {
    const select2 = $(this).data('select2');
    $(window).off(`scroll.select2.${select2.id}`);
  });

  $('#cannes-form-plus').on('change', function() {
      $('#cannes-form fieldset > div:nth-child(6)').toggle();
      $('#cannes-form fieldset > div:nth-child(7)').toggle();
      $('#cannes-form fieldset > div:nth-child(8)').toggle();
  });

    if($('#cannes-form-plus').attr('checked')) {
        $('#cannes-form fieldset > div:nth-child(6)').show();
        $('#cannes-form fieldset > div:nth-child(7)').show();
        $('#cannes-form fieldset > div:nth-child(8)').show();
    }
};

$(initForms);
document.addEventListener('Neos.PageLoaded', initForms, false);
