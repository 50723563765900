import $ from 'jquery';

$(() => {

    $( document ).ready(function() {

        $( ".elancer-palmer-absoluteimage" ).each(function( index ) {
            $( this ).data('offset', $( this ).offset().top );
        });

        //inital height
        calculate_height();
    });

    document.addEventListener('scroll', (event) => {

        calculate_height();

    });

    function calculate_height() {
        let   $scroll = window.scrollY;

        $( ".elancer-palmer-absoluteimage" ).each(function( index ) {
            let $offset = $( this ).data( 'offset' );
            let $speed = $( this ).data( 'speed' );
            $( this ).css('transform', 'translateY(' + ( $offset - $scroll ) * $speed + 'px)');
        });
    }

});
